

























import { packsJSON, LocaleKeys } from "#mws/data";
import GameManager from '#root/game/game';
import { t } from '#root/lib/utils';
import Vue from "vue";

interface PackDefinition {
  name: string;
  voxels: number[];
}

export default Vue.extend({
  name: "BlocksMenu",
  data: () => ({
    shown: false,
    views: [],
    packs: [] as PackDefinition[],
    lk: LocaleKeys,
  }),
  created() {
    Object.entries(packsJSON.packs).forEach(([key, value]) => {
      this.packs.push({
        name: key,
        voxels: value.voxels,
      });
    });
  },
  methods: {
    open(): void {
      this.shown = true;
    },
    selectBlock(voxelId: number): void {
      GameManager.player.updateHoldingMesh(voxelId);
      this.close();
    },
    close(): void {
      this.shown = false;
    },
    t: t
  },
  watch: {
    shown(value: boolean) {
      if (value === false) {
        this.$emit("closed");
      }
    },
  },
});
